import {
    APP_URL,
    AUTH_TOKEN_COOKIE,
    REFRESH_TOKEN_COOKIE,
    PASSWORD_MAX_COMPLEXITY_LEVEL,
    SUBSCRIPTION_ERROR_CODES,
    WELCOME_PAGE_SLUG,
    SHARED_FUNNEL_SAVE_SLUG,
    NEXT_APP_SETTINGS_PLANS_YEARLY_URL,
    NEXT_APP_SETTINGS_PAYMENT_MODAL_URL,
    SUBSCRIPTION_STATUS,
    CURRENCY_SS_KEY,
    COUNTRY_SS_KEY,
} from './constants';
import {
    BUNDLE_PLAN_IDS,
    BEYOND_FUNNELS_PLAN_IDS,
    REGULAR_PLAN_IDS,
    AGENCY_PLAN_IDS,
    INDIVIDUAL_PLAN_IDS,
    BEYOND_BUSINESS_PLAN_IDS,
} from '@app/marketing/constants';

// Utils
import zxcvbn from 'zxcvbn';
import qs from 'query-string';
import Cookies from 'js-cookie';
import { i18n } from 'next-i18next';
import { setAuthTokenForApi } from '@core/api';

// Types
import type { AxiosResponse } from 'axios';
import type { PaymentMethod } from '@stripe/stripe-js';
import type { Address, PayPalCheckoutDetails } from './types';
import { Currency } from './types';

// set cookies and API tokens from signup response
export const setTokensFromResponse = (response: AxiosResponse, rememberMe?: Boolean) => {
    // Extract token from signup response
    const token = response?.data?.data?.attributes?.token;
    const refreshToken = response?.data?.data?.attributes?.refreshToken;

    const cookiesOptions =
        process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? { domain: '.perspective.co' } : {};

    // Set Cookies and token for API
    Cookies.set(AUTH_TOKEN_COOKIE, `Bearer ${token}`, cookiesOptions);

    if (rememberMe) {
        Cookies.set(REFRESH_TOKEN_COOKIE, refreshToken, { ...cookiesOptions, expires: 30 });
    }

    setAuthTokenForApi(Cookies.get(AUTH_TOKEN_COOKIE));
};

export const getPlanIdFromQuery = (planId: string) => {
    if (!planId) return;

    const splitPlanId = planId?.split('-');

    if (splitPlanId.includes(Currency.GBP) || splitPlanId.includes(Currency.USD)) {
        return splitPlanId.slice(0, 3).join('-');
    }
    return splitPlanId.slice(0, 2).join('-');
};

export const splitName = (name: string) => {
    const trimmed = name.trim();
    return trimmed.indexOf(' ') > 0
        ? [trimmed.substring(0, trimmed.indexOf(' ')), trimmed.substring(trimmed.indexOf(' ') + 1)]
        : [trimmed, ''];
};

export const isUserAuthorized = () => !!Cookies.get(AUTH_TOKEN_COOKIE);

export const handleChargebeeError = (
    errorMessage: string,
    isUpdateSubscription: boolean = false,
) => {
    if (!errorMessage) return SUBSCRIPTION_ERROR_CODES.SUBSCRIPTION_ERROR;

    return errorMessage.toLowerCase().includes('insufficient funds')
        ? SUBSCRIPTION_ERROR_CODES.INSUFFICIENT_FUNDS
        : isUpdateSubscription
          ? SUBSCRIPTION_ERROR_CODES.UPDATE_SUBSCRIPTION_ERROR
          : SUBSCRIPTION_ERROR_CODES.SUBSCRIPTION_ERROR;
};

export const handleStripeError = (error: string) =>
    error === SUBSCRIPTION_ERROR_CODES.INSUFFICIENT_FUNDS
        ? error
        : SUBSCRIPTION_ERROR_CODES.GENERIC_DECLINE;

const STRIPE_ERRORS = [
    SUBSCRIPTION_ERROR_CODES.GENERIC_DECLINE,
    SUBSCRIPTION_ERROR_CODES.INSUFFICIENT_FUNDS,
    SUBSCRIPTION_ERROR_CODES.STRIPE_AUTH_FAILED,
];

export const isStripeError = (error: string) => STRIPE_ERRORS.includes(error);

export const buildUrl = (url: string, obj: { [key: string]: string }) => {
    let urlWithParams = url;

    Object.keys(obj).forEach((key) => {
        const value = obj[key];

        if (urlWithParams.indexOf(`:${key}`) > -1) {
            urlWithParams = urlWithParams.replace(`:${key}`, value);
        } else if (urlWithParams.indexOf(`:${key}?`) > -1) {
            urlWithParams = urlWithParams.replace(`:${key}?`, value);
        }
    });

    return urlWithParams;
};

export const getPostLoginUrl = ({
    successRedirectUrl,
    subscriptionId,
    customerAllowedToCreateSubscription,
    welcomeUrl,
    isBundle,
    query,
}: {
    successRedirectUrl?: string;
    subscriptionId?: string;
    customerAllowedToCreateSubscription?: boolean;
    welcomeUrl?: string;
    isBundle?: boolean;
    query: {
        planId?: string;
        coupon?: string;
        partnerKey?: string;
        campaignId?: string;
    };
}) => {
    if (customerAllowedToCreateSubscription && !subscriptionId) {
        if (successRedirectUrl) {
            const finalQuery = qs.stringify({
                ...query,
                subscriptionId,
            });

            return successRedirectUrl + `?${finalQuery}`;
        }

        if (query.planId) return `/checkout?${qs.stringify(query)}`;

        return !query.coupon
            ? '/pricing'
            : `/pricing?${qs.stringify({ coupon: query.coupon, partnerKey: query.partnerKey })}`;
    } else if (isBundle && successRedirectUrl) {
        // Show upsell in Bundle Checkout
        const finalQuery = qs.stringify({
            ...query,
            subscriptionId,
            canCreateSubscription: customerAllowedToCreateSubscription,
        });

        return successRedirectUrl + `?${finalQuery}`;
    }

    if (subscriptionId && welcomeUrl) return welcomeUrl;

    if (subscriptionId) {
        const localizedAppUrl = new URL(getLocalizedAppUrl());

        const editorRedirectPathnameWithoutLeadingSlash = (
            !window?.location
                ? ''
                : ((qs.parse(window.location.search)?.redirect_pathname as string) ?? '')
        ).replace(/^\/+/, ''); // Remove leading slashes

        if (`/${editorRedirectPathnameWithoutLeadingSlash}`.startsWith(localizedAppUrl.pathname)) {
            // redirect_pathname is already localized: we >>overwrite<< the pathname
            localizedAppUrl.pathname = `/${editorRedirectPathnameWithoutLeadingSlash}`;
        } else {
            // Not localized yet: we >>append<< the pathname/resource
            localizedAppUrl.pathname += `/${editorRedirectPathnameWithoutLeadingSlash}`;
        }

        return localizedAppUrl.href;
    }
};

export const getPostSignupUrl = (
    redirectUrl: string,
    query: {
        planId?: string;
        coupon?: string;
        partnerKey?: string;
        campaignId?: string;
    },
) => {
    if (!query.planId)
        return !query.coupon && !query.partnerKey
            ? '/pricing'
            : `/pricing?${qs.stringify({ coupon: query.coupon, partnerKey: query.partnerKey })}`;

    if (redirectUrl) {
        const finalQuery = qs.stringify(query);

        return redirectUrl + `?${finalQuery}`;
    }

    return `/checkout?${qs.stringify(query)}`;
};

export const transformStripeBillingDetails = (
    billingDetails: PaymentMethod.BillingDetails,
): Address => ({
    line1: billingDetails.address?.line1,
    line2: billingDetails.address?.line2,
    city: billingDetails.address?.city,
    country: billingDetails.address?.country?.toUpperCase(),
    state: billingDetails.address?.state,
    zip: billingDetails.address?.postal_code,
    phone: billingDetails.phone,
});

export const transformPaypalCheckoutDetails = (
    checkoutDetails: PayPalCheckoutDetails,
): Address => ({
    line1: checkoutDetails.SHIPTOSTREET,
    city: checkoutDetails.SHIPTOCITY,
    country: checkoutDetails.COUNTRYCODE?.toUpperCase(),
    state: checkoutDetails.SHIPTOSTATE,
    zip: checkoutDetails.SHIPTOZIP,
});

export const estimatePasswordComplexity = (password: string) => {
    if (!password) return 0;

    if (password.length >= 100) return PASSWORD_MAX_COMPLEXITY_LEVEL;

    const passwordData = zxcvbn(password);

    return passwordData?.score;
};

const getLocalizedAppUrl = () => {
    const locale = i18n?.resolvedLanguage;

    return APP_URL + `/${locale}`;
};

export const getWelcomePageUrl = () => getLocalizedAppUrl() + WELCOME_PAGE_SLUG;

export const getSharedFunnelSaveUrl = () => getLocalizedAppUrl() + SHARED_FUNNEL_SAVE_SLUG;

export const getNextAppSettingsPlansUrl = () =>
    getLocalizedAppUrl() + NEXT_APP_SETTINGS_PLANS_YEARLY_URL;

export const getNextAppSettingsPaymentModalUrl = () =>
    getLocalizedAppUrl() + NEXT_APP_SETTINGS_PAYMENT_MODAL_URL;

export const getIsAllowedToCreateSubscription = ({
    subscriptionPlanId,
    subscriptionStatus,
    bundleId,
}: {
    subscriptionPlanId?: string;
    subscriptionStatus?: string;
    bundleId?: string;
}) => {
    if (!subscriptionPlanId) {
        return true;
    }

    // Individual plan customers cannot update their subscription
    if (INDIVIDUAL_PLAN_IDS.includes(subscriptionPlanId)) {
        return false;
    }

    const isBundleLogin = BUNDLE_PLAN_IDS.includes(bundleId);
    const isBeyondFunnelsLogin = BEYOND_FUNNELS_PLAN_IDS.includes(bundleId);

    if (isBundleLogin) {
        return getIsAllowedToPurchaseBundlePlan(subscriptionPlanId, subscriptionStatus);
    }

    if (isBeyondFunnelsLogin) {
        return getIsAllowedToCreateBeyondFunnelsBundle(subscriptionPlanId, bundleId);
    }

    return true;
};

export const getIsAllowedToPurchaseBundlePlan = (
    subscriptionPlanId?: string,
    subscriptionStatus?: string,
) => {
    // Only regular subscriptions in trial can purchase a Crashcourse Bundle
    return (
        REGULAR_PLAN_IDS.includes(subscriptionPlanId) &&
        subscriptionStatus === SUBSCRIPTION_STATUS.InTrial
    );
};

export const getIsAllowedToCreateBeyondFunnelsBundle = (
    subscriptionPlanId?: string,
    bundleId?: string,
) => {
    const hasAgencyPlan = AGENCY_PLAN_IDS.includes(subscriptionPlanId);
    const hasBundlePlan = BUNDLE_PLAN_IDS.includes(subscriptionPlanId);
    const preventBusinessPlan = hasAgencyPlan || hasBundlePlan;

    // If user has a subscription, check for plan and prevent downgrade
    if (preventBusinessPlan && BEYOND_BUSINESS_PLAN_IDS.includes(bundleId)) {
        return false;
    }

    return true;
};

export const getCurrencyFromSS = (): Currency => {
    return sessionStorage.getItem(CURRENCY_SS_KEY) as Currency;
};

export const getCountryFromSS = (): string => {
    return sessionStorage.getItem(COUNTRY_SS_KEY);
};

export const currencySymbolMap: Record<Currency, '€' | '$' | '£'> = {
    [Currency.EUR]: '€',
    [Currency.USD]: '$',
    [Currency.GBP]: '£',
};
