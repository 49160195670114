var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"84c1688c518f26727201dfbfa9eea82912a088ac"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

if (process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

        // Adjust this value in production, or use tracesSampler for greater control
        tracesSampleRate: 0.01,

        replaysOnErrorSampleRate: 1.0,

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.0,

        // You can remove this option if you're not planning to use the Sentry Session Replay feature:
        integrations: [
            Sentry.replayIntegration({
                unblock: ['svg'],
                maskAllText: false,
                networkDetailAllowUrls: [
                    'https://lemon-tool-api.herokuapp.com/api',
                    'https://perspective-lemon-tree.herokuapp.com/api',
                    'https://start.perspective.co/api',
                ],
            }),
        ],

        allowUrls: [/https?:\/\/start\.perspective\.co/],
    });
}
